<template>
    <v-card-text class="mt-0 pt-0">
        <v-card-subtitle
            style="display: flex; justify-content: center;"
            class="mt-0 pt-0"
        >
            <span> {{ $t('awards.subTitleWinnerByArea') }}</span>
        </v-card-subtitle>

        <div class="px-0 winner-simple-text">
            <span class="text-small">{{ getArea.number }}.
                <span
                    class="text-h6"
                    style="margin-left: 0.8em"
                >{{ getArea.name }}</span>
            </span>
        </div>

        <div
            v-if="winner"
            style="display: flex; flex-flow: column wrap; "
            class="winner-simple-text"
        >
            <span
                class="text-h5"
                style="color: #006ff5; justify-self: right; text-align: right"
            >{{ `${winner.name} ${winner.lastname}` }}</span>
            <span
                class="text-h6"
                style="justify-self: right; text-align: right"
            ><strong>{{ `${winner.points_total} puntos` }}</strong>
            </span>

            <v-card
                color="dark"
                dark
                class="rounded-xl mt-6"
            >
                <purposeText :purpose="{ body: winner.purpose }" />

                <v-divider />

                <div
                    class="col-auto pl-6 pr-6 mr-0 text-gray-500"
                    style="display: flex; justify-content: right"
                >
                    <span class="d-inline-block">{{ $t('purposes.published') +": " + dateHelper.getDateTimeToFormatAndLocale(winner.purpose_created_date) }}</span>
                </div>
            </v-card>

            <div
                class="mt-4 text-center"
                style="display: flex; flex-flow: column wrap; align-items: center; color: #006ff5;"
            >
                <div>
                    <img
                        class="mb-1 mt-1"
                        src="@/assets/img/winners/prize_cup.gif"
                        style="max-height: 200px"
                    >
                </div>
                <!--                <span
                    style="color: #006ff5; text-decoration: underline; cursor: pointer"
                    @click="$router.push('ranking/' + getArea.number)"
                > {{ $t('awards.seeRankingPoints') }}</span>-->
                <div
                    class="d-flex align-center mt-4 text-center text-gray-600"
                    style="flex-flow: column wrap;"
                >
                    <span> {{ $t('awards.rememberIfPointsTie') }}</span>

                    <span
                        class="text-h7 mt-3"
                    >
                        Recordar las
                        <a
                            style="color: #006ff5; text-decoration: underline;"
                            href="https://irisglobalback.digitabilities.com/download/bases-del-concurso-mi-mision.pdf"
                            target="_blank"
                        >bases del concurso</a>
                    </span>
                </div>
            </div>
        </div>
    </v-card-text>
</template>

<script>
import listareasCompetition from '@/data/areasCompetition'
import axios from '@/plugins/axios'
import purposeText from '@/components/purpose/purposeText'
export default {
    name: 'AreasList',
    components: { purposeText },
    data: function () {
        return {
            areasCompetition: listareasCompetition,
            winner: null
        }
    },
    computed: {
        getArea() {
            const index = parseInt(this.$route.params.url);
            const area = listareasCompetition[index - 1];
            return {
                name: area.name,
                number: index,
                id: area.id
            }
        }
    },
    created() {
        this.getWinnerFromArea();
    },
    methods: {
        async getWinnerFromArea() {
            try {
                const res = await axios
                    .get('getWinnerFromArea', {
                        params: {
                            idArea: this.getArea.id
                        }
                    });

                this.winner = res.data.result;
            } catch (e) {
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'))
            }
        }
    }
}
</script>

<style scoped>

@media (min-width: 700px) {
.winner-simple-text {
  margin-right: 3em;
  margin-left: 3em;
}
}
</style>
